<template>
  <section class="account">
    <div class="container">
      <h1 class="account__title acc">
        {{ $t("cabinet.welcome") }}, {{ user.first_name }}
      </h1>
      <div class="account__content">
        <div class="account__left">
          <div class="account__info">
            <h2 class="desc-block">{{ $t("cabinet.my_information") }}</h2>
            <div class="account__info-item">
              <span class="info-first">{{ $t("register.first_name") }}</span>
              <input
                class="info-last"
                type="text"
                :value="user.first_name"
                :placeholder="$t('register.enter_your_first_name')"
                disabled
              />
            </div>
            <div class="account__info-item">
              <span class="info-first">{{ $t("register.last_name") }}</span>
              <input
                class="info-last"
                type="text"
                :value="user.last_name"
                :placeholder="$t('register.enter_your_last_name')"
                disabled
              />
            </div>
            <div class="account__info-item">
              <span class="info-first">{{ $t("login.email") }}</span>
              <input
                :placeholder="$t('login.enter_your_email')"
                :value="user.email"
                class="info-last"
                type="text"
                disabled
              />
            </div>
            <div class="account__info-item">
              <span class="info-first">{{ $t("register.phone") }}</span>
              <input
                :value="user.phone"
                class="info-last"
                disabled
                type="text"
              />
            </div>
          </div>
          <div class="mobail_cart__accaunt">
            <div class="account__cards">
              <h2 class="desc-block">{{ $t("cabinet.my_credit_cards") }}</h2>
              <div class="credit__cards">
                <div
                  v-for="card in user.credit_cards"
                  :key="card.id"
                  class="credit__cards-item"
                >
                  {{ $t("credit_card") }}
                  <span class="cards-num">{{ card.pan }}</span>
                  <button class="btn__dell-card">
                    <img
                      alt="icon-trash"
                      src="/img/icon-trash.svg"
                      @click="deleteCard(card.id)"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="account__address">
              <h2 class="desc-block">{{ $t("cabinet.my_address_list") }}</h2>
              <div class="address__list">
                <div
                  v-for="address in user.addresses"
                  :key="address.id"
                  class="address__list-item"
                >
                  <span class="address-title">
                    {{
                      ["home", "office", "friends"].includes(address.category)
                        ? $t("address.categories." + address.category)
                        : address.category
                    }}
                  </span>
                  <span class="address-name">{{ address.street }}</span>
                  <button
                    class="btn-address"
                    @click="deleteAddress(address.id)"
                  >
                    <img alt="icon-trash" src="/img/icon-trash.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="account__orders">
            <h2 class="desc-block">{{ $t("cabinet.my_previous_orders") }}</h2>
            <template v-for="order in orders">
              <OrderCard
                :key="order.id"
                :grid-view="gridView"
                :order="order"
                :cabinet="cabinet"
              />
            </template>
          </div>
        </div>
        <div class="account__right">
          <div class="account__cards">
            <h2 class="desc-block">{{ $t("cabinet.my_credit_cards") }}</h2>
            <div class="credit__cards">
              <div
                v-for="card in user.credit_cards"
                :key="card.id"
                class="credit__cards-item"
              >
                {{ $t("credit_card") }}
                <span class="cards-num">{{ card.pan }}</span>
                <button class="btn__dell-card" @click="deleteCard(card.id)">
                  <img alt="icon-trash" src="/img/icon-trash.svg" />
                </button>
              </div>
            </div>
            <h2 class="desc-block balance-header">
              {{ $t("cabinet.my_balance") }}
            </h2>
            <div class="address__list-item balance-block">
              <span class="address-name balance-text">
                {{ $t("cabinet.total_balance") }}: &euro;{{ user.balance }}
              </span>
            </div>
          </div>
          <div class="account__address">
            <h2 class="desc-block">{{ $t("cabinet.my_address_list") }}</h2>
            <div class="address__list">
              <div
                v-for="address in user.addresses"
                :key="address.id"
                class="address__list-item"
              >
                <span class="address-title">
                  {{
                    ["home", "office", "friends"].includes(address.category)
                      ? $t("address.categories." + address.category)
                      : address.category
                  }}
                </span>
                <span class="address-name">{{ address.street }}</span>
                <button class="btn-address" @click="deleteAddress(address.id)">
                  <img alt="icon-trash" src="/img/icon-trash.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OrderCard from "@/components/OrderCard";

export default {
  components: { OrderCard },
  data() {
    return {
      active_order: null,
      orders: []
    };
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  created() {
    this.me();
    this.getOrders();
  },

  mounted() {
    document.body.classList.remove("page");
  },

  destroyed() {
    document.body.classList.add("page");
  },

  methods: {
    ...mapActions("auth", ["me"]),
    async deleteCard(id) {
      await this.$http.delete(`/api/user/credit-cards/${id}`);
      await this.me();
    },
    async deleteAddress(id) {
      await this.$http.delete(`/api/user/addresses/${id}`);
      await this.me();
    },
    getOrders() {
      this.$http
        .get(`/api/user/orders`)
        .then(response => (this.orders = response.data.data));
    }
  },

  metaInfo() {
    return {
      title: this.$t("cabinet.title")
    };
  }
};
</script>
